<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div class="info-list">
          <div class="type-box">
            <span
              :class="[currentType.id == item.id ? 'active' : '']"
              v-for="(item, index) in bioList"
              :key="index"
              @click="() => cut(item)"
              >{{ item.name }}</span
            >
          </div>
          <div class="type-child-box">
            <span
              :class="[currentChildType.id == item.id ? 'active' : '']"
              v-for="(item, index) in currentType.childList"
              :key="index"
              @click="() => childCut(item)"
              >{{ item.name }}</span
            >
          </div>
          <ul>
            <li v-for="(item, index) in list" :key="index">
              <a @click="goThere(item)">
                <img :src="item.servicePath + item.cover" />
              </a>
              <p>
                {{ item.brief }}
                <span>more</span>
              </p>
            </li>
          </ul>
          <div class="footer" v-if="list.length > 0">
            <Page
              :current="page.pageNo"
              :page-size="page.pageSize"
              :total="page.total"
              @on-change="pageChange"
            ></Page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      bioList: [],
      currentType: {},
      currentChildType: {},
      list: [],
      page: {
        pageNo: 1,
        pageSize: 9,
        total: 0,
      },
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    $route() {
      this.getList();
    },
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
    currentChildType() {
      this.getData();
    },
  },
  methods: {
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          // pageSize: 10,
          // pageNo: 1,
        }).then((res) => {
          this.bioList = res;
          this.currentType = res.find(item => item.keyword == 'mid');
          this.currentChildType =
            this.currentType.childList.length > 0
              ? this.currentType.childList[0]
              : {};
        });
      }
    },
    getData() {
      let keyword = "";
      if (Object.keys(this.currentChildType).length == 0) {
        keyword = this.currentType.keyword;
      } else {
        keyword = this.currentChildType.keyword;
      }
      this.$post("websitenews/list/" + keyword, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.list = res.list || [];
        this.page.total = +res.total;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });
    },
    cut(item) {
      this.currentType = item;
      this.currentChildType =
        item.childList.length > 0 ? item.childList[0] : {};
      this.page.pageNo = 1
    },
    childCut(item) {
      this.currentChildType = item;
      this.page.pageNo = 1
    },
    //分页
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getData();
    },
    goThere(item) {
      this.$router.push({
        name: "mnewsInfoThree",
        query: {
          id: item.id,
          key: this.$route.name,
          keyword: this.currentChildType.keyword,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      .info-list {
        .type-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;
          span {
            display: inline-block;
            width: 30%;
            border: 1px solid #e0e0e0;
            text-align: center;
            padding: 8px 0;
            border-radius: 4px;
            letter-spacing: 5px;
            cursor: pointer;
          }
          .active {
            background: #ff9c00;
            border-color: #ff9c00;
            color: #fff;
          }
        }
        .type-child-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;
          span {
            display: inline-block;
            width: 48%;
            border: 1px solid #e0e0e0;
            text-align: center;
            padding: 8px 0;
            border-radius: 4px;
            letter-spacing: 5px;
            cursor: pointer;
          }
          .active {
            background: #ff9c00;
            border-color: #ff9c00;
            color: #fff;
          }
        }
        ul {
          list-style: none;
          overflow: hidden;
          li {
            width: 100%;
            height: 10.867rem;
            margin-bottom: 0.5rem;
            position: relative;
            a {
              color: #333;
              img {
                display: block;
                width: auto;
                height: 10.867rem;
              }
            }
            p {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              padding: 0.3rem 3%;
              box-sizing: border-box;
              background: rgba(0, 0, 0, 0.4);
              color: #ffffff;
              font-size: 0.577rem;
              span {
                float: right;
              }
            }
          }
        }
        .footer {
          text-align: center;
          padding-top: 10px;
        }
      }
    }
  }
}
</style>